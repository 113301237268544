import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SeriesMiniCard from "../components/series-mini-card"

const SeriesIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const series = data.series.nodes
    .map(node => node.childMarkdownRemark.frontmatter)
    .sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'}))

  // Collate the posts by series.
  const postsBySeries = new Map()
  for (const post of data.posts.nodes) {
    if (post.childMarkdownRemark.frontmatter.series) {
      const seriesId = post.childMarkdownRemark.frontmatter.series.frontmatter.seriesId
      const subSeriesId = post.childMarkdownRemark.frontmatter.subSeries && post.childMarkdownRemark.frontmatter.subSeries.frontmatter.seriesId

      if (postsBySeries.has(seriesId)) {
        postsBySeries.get(seriesId).push(post)
      } else {
        postsBySeries.set(seriesId, [post])
      }

      if (postsBySeries.has(subSeriesId)) {
        postsBySeries.get(subSeriesId).push(post)
      } else {
        postsBySeries.set(subSeriesId, [post])
      }
    }
  }

  // Group series by first letter.
  let seriesGroups = series.reduce((r, e) => {
    // get first letter of name of current element
    let group = e.name[0].toUpperCase()

    // if there is no property in accumulator with this letter create it
    if(!r[group]) r[group] = {group, children: [e]}
    
    // if there is push current element to children array for that letter
    else r[group].children.push(e);
    
    // return accumulator
    return r;
  }, {})

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Series Index" />

      <h1 className="text-center mt-2 mb-4">Series Index</h1>
      
      <ul style={{ listStyle: `none` }}>
        {Object.values(seriesGroups).map(group => {
          return (<li><h2 className="mt-4">{group.group}</h2>
            {group.children.map(series => {
        
              return (
                <li key={series.fieldValue} className="mb-0">
                  <SeriesMiniCard series={series} posts={postsBySeries.get(series.seriesId)}/>
                </li>
              )
            })}
          </li>)
        })}
      </ul>
    </Layout>
  )
}

export default SeriesIndex

export const pageQuery = graphql`
  query SeriesIndex {
    site {
      siteMetadata {
        title
      }
    }
    series: allFile(
      filter: {sourceInstanceName: {eq: "series"}}
      sort: {fields: [childrenMarkdownRemark___frontmatter___name], order: ASC}
    ) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            name
            seriesId
            startDate(formatString: "MMMM DD, YYYY")
            endDate(formatString: "MMMM DD, YYYY")
            coverImage {
              childImageSharp {
                gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
    posts: allFile(filter: { sourceInstanceName: { eq: "posts"} }, sort: { fields: [childrenMarkdownRemark___frontmatter___postDate], order:DESC}) {
      nodes {
        childMarkdownRemark {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            lessonDate(formatString: "MMMM DD, YYYY")
            series {
              frontmatter {
                seriesId
              }
            }
            subSeries {
              frontmatter {
                seriesId
              }
            }
            presenters {
              frontmatter {
                presenterId
                name
              }
            }
          }
        }
      }
    }
  }
`
