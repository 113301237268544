import React from "react";
import { Link, StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function getCoverImage(props) {
  if (props.series) {
    const series = props.series
    const coverImage = getImage(series.coverImage)

    return (
      <div>
        <Link to={`/series/${series.seriesId}`}>
          <GatsbyImage image={coverImage} className="h-48 w-48 flex-none bg-cover rounded-t rounded-l text-center overflow-hidden" alt={series.title} />
        </Link>
      </div>
    )
  } else {
    return (
      <StaticQuery
        query={graphql`
          query DefaultSeriesAlbumCover {
            file(relativePath: {eq: "album-cover-default.jpg"}) {
              childImageSharp {
                gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
              }
            }
          }
        `}
        render={data => (<div>
          <Link to={`/series/${props.series.seriesId}`}>
            <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} className="h-48 w-48 flex-none bg-cover rounded-t rounded-l text-center overflow-hidden" alt="Default album cover" />
          </Link>
        </div>)}
      />
    )
  }
}

function getPresenterAvatars(presenters) {
  const returnElements = []

  for (const presenter of presenters) {
    const initials = presenter.name ? presenter.name.split(/[ ]+/).map(word => word[0].toUpperCase()).join('') : '??'
    returnElements.push((
      <Link to={`/presenter/${presenter.presenterId}`}>
        <div className="bg-pink-800 w-10 h-10 flex justify-center items-center rounded-full uppercase font-bold text-white">
          {initials}
        </div>
      </Link>
    ))
  }

  return returnElements
}

/**
 * Render a card representing a single post.
 *
 * @param {object} props Properties object containing a post field with the necessary post data.
 * @returns React element containing a post mini card.
 */
function SeriesMiniCard(props) {  
  const coverImage = getCoverImage(props)

  // Walk through the list of posts and collate by presenter.
  const presenterMap = new Map()
  let startDate = 'Unknown'
  let endDate = 'Unknown'

  if (props.posts) {
    for (const post of props.posts) {
      for (const presenter of post.childMarkdownRemark.frontmatter.presenters) {
        if (!presenterMap.has(presenter.frontmatter.presenterId)) {
          presenterMap.set(presenter.frontmatter.presenterId, presenter.frontmatter)
        }
      }
    }

    if (props.posts.length > 0) {
      endDate = props.posts[0].childMarkdownRemark.frontmatter.lessonDate
      startDate = props.posts[props.posts.length - 1].childMarkdownRemark.frontmatter.lessonDate
    }
  }

  const presenters = Array.from(presenterMap.values())
  const presenterAvatars = getPresenterAvatars(presenters)

  return (
    <div className="max-w-full mb-4 border-gray-400 bg-white flex rounded-b rounded-t">
      {coverImage}
      <div className="p-4 flex flex-col justify-between leading-normal">
        <div>
          <p className="text-sm text-gray-600 flex items-center">
            SERIES - {props.posts ? props.posts.length : 0} lessons
          </p>
          <Link to={`/series/${props.series.seriesId}`} itemProp="url">
            <div className="text-gray-900 font-headline text-xl">{props.series.name}</div>
          </Link>
          <p className="text-gray-600">{startDate} - {endDate}</p>
          <p className="text-italic">Presented by:</p>
          {presenters.length > 0 ? 
            <ol className="text-gray-900 leading-none ml-2 mb-2">
              {presenters.map(p => {
                return (
                  <Link to={`/presenter/${p.presenterId}`} itemProp="url">
                    <li className="font-bold mb-1">{p.name}</li>
                  </Link>)
              })}
            </ol>
          : <p>No one, yet!</p>}
        </div>
        <div className="flex flex-wrap items-center">
          {presenterAvatars}
        </div>
      </div>
    </div>
  )
}

export default SeriesMiniCard